/*jslint browser: true, nomen: true */
/*global $ */
'use strict';



function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10)/100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter ($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */

var App;

function ExternalLinkHandler () {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings () {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };
  this.bindPopovers = function () {
    $('[data-toggle="popover"]').popover();
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });
  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
    });
  };

  this.bindMagnificpopup = function(){
    $('.gallery-image').magnificPopup({
         type: 'image',
         callbacks:
          {
              markupParse: function(template, values, item)
                  {
                      values.description = item.el.data('description');
                  }
          },
          gallery:{
            enabled:true
          },

          image:
              {
                  headerFit: true,
                  captionFit: true,
                  preserveHeaderAndCaptionWidth: false,
                  markup:
                  '<div class="mfp-figure">'+
                      '<figure>'+
                          '<header class="mfp-header">'+
                              '<div class="mfp-top-bar">'+
                                  '<div class="mfp-title"></div>'+
                                  '<div class="mfp-close"></div>'+
                                  '<div class="mfp-decoration"></div>'+
                              '</div>'+
                          '</header>'+
                          '<section class="mfp-content-container">'+
                              '<div class="mfp-img"></div>'+
                          '</section>'+
                          '<footer class="mfp-footer">'+
                              '<figcaption class="mfp-figcaption">'+
                                  '<div class="mfp-bottom-bar-container">'+
                                      '<div class="mfp-bottom-bar">'+
                                          '<div class="mfp-counter"></div>'+
                                          '<div class="mfp-description"></div>'+
                                          '<div class="mfp-decoration"></div>'+
                                      '</div>'+
                                  '</div>'+
                              '</figcaption>'+
                          '</footer>'+
                      '</figure>'+
                  '</div>',
              }
    });
  };

  this.bindMasonary = function () {
    var gridMasonry = $('.grid');
    var firstElement = $(gridMasonry.find('.grid-item')[0]);

    var loader = $('<div class="loader-image"></div>');
    var loaderImg = $('<img src="/images/ring.gif"/>');
    var heightCheckInterval;

    loader.append(loaderImg);

    $('#content').prepend(loader);

    gridMasonry.imagesLoaded(function () {
      gridMasonry.css({'visibility': 'visible'});

      loader.remove();

      gridMasonry.masonry({
        itemSelector: '.grid-item',
        percentPosition: true,
      });

    });

    // var originalHeight = firstElement.height();

    // heightCheckInterval = setInterval(function () {
    //   var newHeight = firstElement.height();

    //   if (newHeight !== originalHeight) {
    //     gridMasonry.masonry("layout");
    //     console.log(newHeight + ' !== ' + originalHeight + " triggering masonry.layout...");
    //   }

    //   originalHeight = newHeight;
    // }, 100);

    setInterval(function () {
      gridMasonry.masonry("layout");
    }, 1000);


    this.infiniteScroll = function() {

        var page = 2;

        var count = 30;

        var totalCount = $("#totalCount").val();

        $('#loadMore').click (function (e) {

          e.preventDefault();
          if(count < totalCount){
            $('#loading').show();

            var hiddenDiv = $('<div class="hidden"></div>');

            gridMasonry.append(hiddenDiv);

            $.get( "p"+page, function( content ) {

              var $content = $(content);

              hiddenDiv.append($content);


              hiddenDiv.imagesLoaded(function() {
                gridMasonry.append($content);
                hiddenDiv.remove();

                gridMasonry.masonry('appended', $content, true);

                (new UIBindings).youtubeApi($content);

                $('#loading').hide();
              });


              page++;

              count = (count) + 30;

              if(count >= totalCount){
                $('#loadMore').hide();
              }

            });

          }

        });
    }

    return this;
  };

  this.bindDropdownhover = function(){
    $('ul.dropdown-menu [data-toggle="dropdown"]').on('click', function(event) {
        var mqDesktop = window.matchMedia("(min-width: 768px)");
        event.preventDefault();
        event.stopPropagation();

        $(this).parent().siblings().removeClass('open');
        $(this).parent().toggleClass('open');

    });
  };

  this.youtubeApi = function(selector, options){

        var videosElements = selector ? selector.find('[data-video]') : $('[data-video]');


        // console.log($(document).find('[data-video]'));

        if (!videosElements.length) {  return; }

        var player,
            ytOptions = {
                height: '390',
                width: '640',

                playerVars: {
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 0,
                    autohide: 1,
                    controls: 2,
                    vq: 'hd720',
                    disablekb: 0,
                    autoplay: 0,
                    loop: 0,
                    playsinline: 1,
                    cc_load_policy: 1,
                    fs: 1,
                    enablejsapi: 1
                },
                videoId: '',
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': videoOnStateChange
                }
            };

        function onPlayerReady(event) {
        }


        function videoOnStateChange(event) {
            var _player, _videoObject, _videoId, _videoTitle, _videoRead;
            _player = event.target;

            _videoId = event.target.getVideoData().video_id;
            _videoObject = $('[data-play="' + _videoId + '"]');
            _videoTitle = $('.videotitle-' + _videoId );
            _videoRead = $('.videoread-' + _videoId );

            switch (event.data) {
                case 0:
                case -1:
                    videoPlay('resume');
                    break;
                case 1:
                case 3:
                    videoPlay('pause');
                    break;
                case 2:
                    videoPlay('resume');
            }

            function videoPlay(action) {
                if (action === 'pause') {
                    _videoObject.addClass('playing');
                    _videoTitle.addClass('playing');
                    _videoRead.addClass('playing');
                } else {
                    _videoObject.removeClass('playing');
                    _videoTitle.removeClass('playing');
                    _videoRead.removeClass('playing');
                }
            }
        }

        var id, videoId, element;

        videosElements.each(function () {
            element = $(this);
            videoId = element.data('video')
            ytOptions.videoId = videoId;
            ytOptions.playerVars.cc_lang_pref = 'eng';
            if (YT.Player) {
              player = new YT.Player(element.data('id'), ytOptions);
              $('[data-play="' + videoId + '"]').data('player', player);
            }
        });


         $('.player').on('click', function(event){
            event.preventDefault();
            if ($(this).data('player')){
                $(this).data('player').playVideo();
            }

            return false
        });


    };

    this.googleMapsApi = function () {
    $('[data-google-map]').each(function () {
      var $mapElement = $(this);
      console.log('$mapElement', $mapElement);


      var map = $mapElement[0],
        mapZoom = 8,
        lats = -26.010754,
        lngs = 28.127379,
        links = true,
        t = [],
        x = [],
        y = [],
        h = [];
      var bounds = new google.maps.LatLngBounds();

      var styles = [
        {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
            { "color": "#cccccc" },
            { "visibility": "on" }
          ]
        },{
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
            { "color": "#cccccc" },
            { "visibility": "on" }
          ]
        },{
          "featureType": "road.highway",
          "stylers": [
            { "visibility": "on" },
            { "color": "#cccccc" }
          ]
        },{
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            { "color": "#b3d1ff" },
            { "visibility": "on" }
          ]
        },{
          "featureType": "landscape.natural.terrain",
          "elementType": "geometry.fill",
          "stylers": [
            { "visibility": "on" },
            { "color": "#cccccc" }
          ]
        }
      ];


      var map_options = {
        center: {lat: lats, lng: lngs},
        zoom: mapZoom,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: true,
        draggable: true,
        styles: styles,
      };

      var googleMap = new google.maps.Map($mapElement[0], map_options);

      // map.setOptions({styles: styles});

      var mapMarkers = $(map).data('map-markers');

      var info_window = new google.maps.InfoWindow({
        content: 'loading'
      });

      $.each(mapMarkers, function (i, mapMarker) {
        var latLng = new google.maps.LatLng(mapMarker.lat, mapMarker.lng);
        bounds.extend(latLng);

        var m = new google.maps.Marker({
          map: googleMap,
          animation: google.maps.Animation.DROP,
          position: latLng,
          icon: mapMarker.icon
        });

        var info_window = new google.maps.InfoWindow({
          content: '<p class="map-title">' + mapMarker.title + '</p><p>' + mapMarker.content + '</p><p><a href="https://www.google.com/maps/place/' + mapMarker.lat +','+ mapMarker.lng +'">View on Google Maps</a></p>'
        });

        google.maps.event.addListener(m, 'click', function () {
          info_window.open(googleMap, m);
        });

        googleMap.fitBounds(bounds);

      });

    });
  };

  this.fullCalendar = function (){

    console.log("hello");

    $('#calendar').fullCalendar({
        googleCalendarApiKey: 'AIzaSyAqXVslgrb4FSGdlFIU8DmaXFw1LaGI80o',
        events: {
            googleCalendarId: '8279vi0mvt813ngssojifg0qqg@group.calendar.google.com',
        }
      });
  };

  this.menuActive = function(){
    if ( $("#primaryNav").hasClass("in") ) {
        // $(".navbar-toggle").addClass("active");
    }

    var toggle = $(".navbar-toggle");

    $('#primaryNav').on('show.bs.offcanvas', toggleClass);
    $('#primaryNav').on('hide.bs.offcanvas', toggleClass);

    function toggleClass(evt){
        console.log(evt.target)
        if (!$(evt.target).hasClass('in')) {
          toggle.addClass("active");
        } else {
          toggle.removeClass("active");
        }
    }
  };

}

$(function () {

  $('.ui.menu .ui.dropdown').dropdown({
    on: 'hover'
  });

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new UIBindings).bindTooltips();

  (new UIBindings).bindPopovers();

  (new UIBindings).bindSlickCarousels();

  (new UIBindings).bindSharing();

  (new PageTimer).logToConsole();

  (new UIBindings).bindMasonary().infiniteScroll();

  (new UIBindings).bindDropdownhover();

  (new UIBindings).bindMagnificpopup();

  (new UIBindings).youtubeApi();

   (new UIBindings).googleMapsApi();

  (new UIBindings).fullCalendar();

  (new UIBindings).menuActive();

});

window.onYouTubeIframeAPIReady = function () {
    (new UIBindings).youtubeApi();
};
